import React from "react";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import LocationOverview from "../components/LocationOverview";
import Call from "../components/Call";
import AlarmIcon from "../components/AlarmIcon";

const Home = (props) => {
  const site = props.data.site.siteMetadata;
  const introduction = props.data.introduction;
  const hero = props.data.hero;
  const service = props.data.service;

  // const services = props.data.services.edges;
  // const features = props.data.features.edges;

  return (
    <Layout bodyClass="page-home">
      <SEO title={site.title} />
      <Helmet>
        <meta name="description" content={site.description} />
      </Helmet>

      <Hero>
        <div dangerouslySetInnerHTML={{ __html: hero.html }} />
      </Hero>

      <main className="content">

      <div className="strip py-2 mt-2">
        <div className="container">
          <h2 className="text-center ">
            <AlarmIcon width="1.5em" height="1.5em"/><br/>
            <span>Elternzeit - aktueller Hinweis:</span>
          </h2>
        </div>
      </div>
      <div className="strip-grey py-4">
          <div className="container">
            <div class="row justify-content-center">
              <div class="col-12 col-lg-9">
                <p>
                  Liebe Patientinnen und Patienten,
                </p>
                <p>
                  Ich befinde mich <strong>bis voraussichtlich Januar 2026</strong> in <strong>Elternzeit</strong>.
                </p>
                <p>
                  Derzeit bin ich <strong>ausschließlich per E-Mail</strong> zu erreichen. Für die Bearbeitung Ihrer E-Mail Anfragen bitte ich um Geduld.
                </p>
                <p>
                  Für Telefonate plane ich <strong>ab Herbst 2025 feste Telefonzeiten</strong> einzurichten.
                  Sobald es Neuigkeiten dazu gibt, werde ich Sie hier informieren.
                </p>
                <p>
                  Vielen Dank für Ihr Verständnis!
                </p>
                <p className="mb-0">
                  Herzliche Grüße,<br/>
                  Juliane Bartelt
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="strip">
          <div className="container py-5 py-lg-8">
            <div className="row justify-content-start">
              <div className="col-12">
                <div dangerouslySetInnerHTML={{ __html: introduction.html }} />
              </div>
            </div>
          </div>
        </div>

        <div className="strip-grey py-4">
          <div className="container">
            <LocationOverview />
          </div>
        </div>

        {/* <div className="strip-secondary py-4">
          <div className="container">
            <div className="col-12 mt-2">
              <h2 className="text-center">Hausbesuche</h2>
              <p>
                Lorem ipsum dolor sitser amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
              </p>
            </div>
          </div>
        </div> */}

        <div className="strip py-6">
          <div className="container">
            {/* <div dangerouslySetInnerHTML={{ __html: service.html }} /> */}

            <h2>Leistungsübersicht</h2>

            <p>
              Als freiberufliche Logopädin biete ich in zwei Praxen Sprach-, Sprech-, Stimm-, Schlucktherapie an. Durch meine Weiterbildung kann ich auch Kiefergelenksprobleme mit
              einer craniosacralen Faszientherapie behandeln. Sollte Ihre Verordnung und Ihr Krankheitsbild es nötig machen, können wir die Therapie auch bei Ihnen in der
              Häuslichkeit durchführen.
            </p>

            <h3>Störungsbilder</h3>

            <div class="row">
              <div class="col-12 col-md-4">
                <ul className="mb-0">
                  <li>Aphasie </li>
                  <li>Demenz </li>
                  <li>Dysarthrie</li>
                </ul>
              </div>
              <div class="col-12 col-md-4">
                <ul className="mb-0">
                  <li>Sprechapraxie </li>
                  <li>Dysphagie </li>
                  <li>CMD</li>
                </ul>
              </div>
              <div class="col-12 col-md-4">
                <ul className="mb-0">
                  <li>Myofunktionelle Störungen </li>
                  <li>Sprachentwicklungs&shy;störungen </li>
                  <li>Aussprachestörungen</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="strip-primary-gradient py-6 text-center">
          <div className="container">
            <h2>NEUROvitalis</h2>

            <div class="row justify-content-center">
              <div class="col-12 col-lg-9">
                <p>
                  Seit Mai 2022 bin ich NEUROvitalis Trainerin. Dabei handelt es sich um ein von der Universität zu Köln entwickeltes Gruppenprogramm zum Hirnleistungstraining.
                  Gegenwärtig werden die Teilnehmergruppen zusammengestellt - bei Interesse melden Sie sich gerne.
                </p>
                <Link className="btn btn-outline-light" to={"/neurovitalis"}>
                  zum NEUROvitalis Basisprogramm
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="strip py-4">
          <div className="container text-center">
            <div className="row">
              <div className="col-12">
                <h2 id="contact">Kontakt</h2>
                {/*
                <p>
                  Sie erreichen mich <strong>Montag - Freitag</strong> in der Zeit von <strong>08:00 - 18:00 Uhr</strong>.
                  <br />
                  Sollte ich Ihren Anruf nicht entgegen nehmen können, melde ich mich schnellstmöglich zurück.
                </p>
                */}
              </div>
              <div className="col-lg-6 offset-lg-3">
                <Call plain showButton />
              </div>
            </div>
          </div>
        </div>

        {/* {services.length > 0 && (
        <div className="strip">
          <div className="container pt-6 pb-6 pb-md-10">
            <div className="row justify-content-start">
              {services.map(({ node }) => (
                <div key={node.id} className="col-12 col-md-4 mb-1">
                  <div className="service service-summary">
                    <div className="service-content">
                      <h2 className="service-title">
                        <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                      </h2>
                      <p>{node.excerpt}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <Link className="button button-primary" to="/services/">
                  View All Services
                </Link>
              </div>
            </div>
          </div>
        </div>
      )} */}

        {/* {features.length > 0 && (
        <div className="strip strip-grey">
          <div className="container pt-6 pb-6 pt-md-10 pb-md-10">
            <div className="row justify-content-center">
              {features.map(({ node }) => (
                <div key={node.id} className="col-12 col-md-6 col-lg-4 mb-2">
                  <div className="feature">
                    {node.image && (
                      <div className="feature-image">
                        <img src={node.image} />
                      </div>
                    )}
                    <h2 className="feature-title">{node.title}</h2>
                    <div className="feature-content">{node.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )} */}
      </main>
    </Layout>
  );
};

export const query = graphql`
  query {
    # services: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/services/.*/" } }, sort: { fields: [frontmatter___weight], order: ASC }, limit: 6) {
    #   edges {
    #     node {
    #       id
    #       frontmatter {
    #         title
    #         date(formatString: "DD MMMM YYYY")
    #       }
    #       fields {
    #         slug
    #       }
    #       excerpt
    #     }
    #   }
    # }
    hero: markdownRemark(fileAbsolutePath: { regex: "/content/hero.md/" }) {
      html
      frontmatter {
        image
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
        title
      }
    }
    introduction: markdownRemark(fileAbsolutePath: { regex: "/content/introduction.md/" }) {
      html
    }
    service: markdownRemark(fileAbsolutePath: { regex: "/content/service.md/" }) {
      html
    }
    features: allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

export default Home;
